.ContextualMenu {
	border-radius: 8px;
	background: var(--color-panel-face);
	box-shadow: var(--popup-menu-shadow);
	padding: 4px;

	> button.menu-item {
		cursor: pointer;
		display: flex;
		appearance: none;
		flex-direction: column;
		margin: 0;
		padding: 4px 9px;
		width: 100%;
		box-sizing: border-box;
		font-size: 13px;
		border-radius: 5px;
		color: var(--color);

		> .label {
		}

		&:hover {
			background-color: var(--color-selected);
			color: white;
		}
	}
}