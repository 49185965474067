@import "animations";

.CardSelect {
	position: relative;
	width: 100%;
	padding: --inset-padding;
	overflow: hidden;

	> .card {
		display: flex;
		flex-direction: row;
		height: 82px;
		background: var(--color-panel-inset);
		border-radius: 10px;

		> .description,
		> .edit {
			box-shadow: var(--inspector-inset-shadow);
			&:hover {
				background: var(--color-panel-inset-hover);
			}
		
			&:active {
				background: var(--color-panel-inset-deeper);
			}
		}
	
		> .preview {
			pointer-events: none;
			position: absolute;
			left: var(--inset-padding);
			top: var(--inset-padding);

			> .CardPreview {
				transform: scale(0.5);
				transform-origin: top left;
			}
		}
	
		> .description {
			cursor: pointer;
			height: inherit;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 8px 5px 10px 72px;
			box-sizing: border-box;
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
	
			> .name {
				font-weight: 500;
			}
	
			> .hint {
				color: var(--color-softer);
				font-size: 14px;
			}
	
			> .name + .hint {
				margin-top: 6px;
			}
		}

		> .edit {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			justify-items: center;
			flex-shrink: 0;
			border-left: 1px solid var(--color-panel-face);
			padding: 14px;
			font-size: 18px;
			border-top-right-radius: inherit;
			border-bottom-right-radius: inherit;
		}

		&.newly-selected {
			> .preview,
			> .description > .name,
			> .description > .hint {
				animation: card-select-slide-up 1s $ease-out-quint backwards;
			}

			> .description > .name {
				animation-delay: 0.2s;
			}

			> .description > .hint {
				animation-delay: 0.4s;
				animation-duration: 1.5s;
			}
		}
	}
}

@keyframes card-select-slide-up {
	from {
		transform: translateY(100px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}