@import "animations";

.Creator {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
	overflow: hidden;
	box-sizing: border-box;
	transition: opacity 0.9s $ease-out-quint;

	&.busy {
		pointer-events: none;
		opacity: 0.5;
		transition-duration: 0.6s;
	}

	&.closing {
		transition: none;
		animation: blur-out 0.4s $ease-out-quint forwards;
	}

	label {
		color: var(--color);
	}

	> .Toolbar {
		flex-shrink: 0;
	}

	> .workspace {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		overflow: hidden;

		> .stack {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			transition: opacity 0.5s $ease-out-quint;

			&.locked {
				pointer-events: none;
				opacity: 0.8;
			}
		}
	}
}

.App.small-ui .Creator {
	> .workspace {
		width: 300vw;
		position: relative;
		transition: left 0.3s $ease-out-quint;

		> .stack > .CardList {
			width: 100vw;
		}
	}

	&.view-preview > .workspace {
		left: -100vw;
	}

	&.view-inspector > .workspace  {
		left: -200vw;
	}
}