@mixin solidify {
	-moz-user-select: -moz-none;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	user-select: none; }

@mixin text-selectable {
	-webkit-user-drag: auto;
	-moz-user-select: auto;
	-webkit-user-select: auto;
	user-select: auto; }
