@import "animations";
@import "effects";

.StackSelect {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100);

	> .stacks {
		flex-grow: 1;
		display: flex;
		max-width: 100vw;
		justify-content: flex-start;
		align-content: flex-start;
		overflow-y: auto;
		overflow-x: hidden;
	}

	animation: blur-in 0.7s $ease-out-quint;
}

.App.large-ui .StackSelect {
	> .stacks {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.StackPreview {
		> .definitions {
			align-items: center;
		}
	}
}

.App.small-ui .StackSelect {
	> .stacks {
		flex-direction: column;
	}

	.StackPreview {
		flex-direction: row;
		width: 100vw;
		height: 170px;
		box-sizing: border-box;
	
		.CardPreview {
			font-size: 12px;
		}
	
		> .definitions {
			align-items: flex-start;
	
			.StateButton .name {
				text-align: left;
	
				.value {
					font-size: 20px;
					font-weight: bold;
					word-wrap: break-word;
					word-break: keep-all;
					white-space: normal;
					overflow-x: hidden;
					hyphens: auto;
				}
			}
	
			.lastModified {
				font-size: 16px;
			}
		}
	}
}