@import "animations";

.DraggableList {
	&.dragging {
		cursor: grabbing;
	}
}

.DraggableListItem {
	position: relative;
	border-top: 1px solid var(--color-textbox-border);
	transition: 0.3s $ease-snappy;
	transition-property: box-shadow, transform;

	&.newly-created {
		animation: newly-created 1s $ease-out-quint;
		transform-origin: center top;
	}

	> .handle-bar {
		cursor: grab;
		padding: 5px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background: var(--color-panel-face-shade);
		line-height: 1em;
		text-shadow: 0 1px 0 var(--color-contrast-text-shadow);

		> .title {
			flex-shrink: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-wrap: break-word;
			padding-right: 90px;
			height: 1.1em;
		}

		> .handle {
			padding: 0 15px 0 10px;
			font-size: 20px;
			color: var(--color-soft);
		}
	}

	> .actions {
		display: flex;
		flex-direction: row;
		position: absolute;
		right: 0;
		top: 0;
		padding: 0 5px;

		> .action {
			cursor: pointer;
			padding: 7px 10px;
			opacity: 0.6;
		
			&:hover {
				opacity: 1;
				color: var(--color-selected);
			}
	
			&:active {
				opacity: 1;
				color: var(--color-selected-deep);
			}
		}
	}

	&.dragged {
		position: relative;
		z-index: 200;
		box-shadow:
			0 0 20px -10px hsla(0, 0%, 0%, 0.65),
			0 15px 10px -5px hsla(0, 0%, 0%, 0.15);
		transition-property: box-shadow;
		background: var(--color-panel-face);
		border-radius: 10px;

		> .handle-bar {
			cursor: grabbing;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}

		> *:not(handle-bar) {
			pointer-events: none;
		}
	}
}

@keyframes newly-created {
	from {
		transform: scaleY(0.8);
		filter: blur(3px);
		opacity: 0;
	}
	to {
		transform: scaleY(1);
		filter: blur(0px);
		opacity: 1;
	}
}