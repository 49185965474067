@import "animations";

.ImageInput {
	position: relative;
	width: 300px;
	height: 140px;
	border-radius: 20px;

	> iframe {
		position: relative;
		width: inherit;
		height: inherit;
		border: none;
		border-radius: inherit;
	}

	&.uploading > iframe {
		pointer-events: none;
	}

	.dropzone-cover {
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		border-radius: inherit;
		background-color: var(--color-panel-inset);
		transition: background-color 0.15s $ease-snappy;
		overflow: hidden;
		transform: translate3d(0, 0, 0);

		> .outline {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: inherit;
			box-shadow: var(--inspector-inset-shadow);
			transition: box-shadow 0.15s $ease-snappy;
			transform: translate3d(0, 0, 0);
		}
	
		> .SmartImage {
			pointer-events: none;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: inherit;
			background-size: cover;
			background-position: center center;
			transition: 0.7s $ease-snappy;
			transition-property: transform, filter;
		}

		> .drop-label {
			pointer-events: none;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			box-sizing: border-box;
			padding-left: 10%;
			padding-right: 10%;
			color: var(--color);
			text-align: center;
			font-size: 17px;
			line-height: 1.4em;
		}

		> .preview {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center center;
			opacity: 0.5;
			transform: scale(1.25);
			filter: blur(5px);
		}
	}

	.center-overlay {
		z-index: 2;
	}

	&.has-value > .dropzone-cover {
		> .drop-label {
			color: white;
			text-shadow:
				0 0 1px hsl(0, 0%, 0%),
				0 1px 3px hsl(0, 0%, 0%);
		}
	}

	&:not(.uploading).dragged-over,
	&:not(.uploading).hover {
		> .dropzone-cover {
			background-color: var(--color-panel-inset-deeper);
		
			> .SmartImage {
				transform: scale(1.25);
				filter: blur(10px) brightness(0.8);
			}

			> .outline {
				box-shadow: inset 0 0 0 3px var(--color-panel-face-half);
			}
		}
	}
}