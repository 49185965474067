@import "animations";
@import "effects";

.StackCreate {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background: var(--background);
	animation: blur-in 0.7s $ease-out-quint;
	
	&.busy {
		> .container {
			pointer-events: none;
			filter: opacity(0.5) saturate(0.8) blur(2px);
			transition-duration: 2s;
		}
	}

	&.closing {
		transition: none;
		animation: blur-out 0.4s $ease-out-quint forwards;
	}
	
	> .container {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		transition: filter 0.6s $ease-out-quint;

		.guiding-star-hint {
			pointer-events: none;
			padding: 0 15px;
			margin: 10px auto;
			max-width: 800px;
			box-sizing: border-box;
			animation: blur-in 1.6s $ease-out-quint 0.2s backwards;
			
			> .container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 20px;
				background-color: var(--card-background);
				border-radius: 14px;

				> .GuidingStar {
					flex-shrink: 0;
					margin-inline-end: 0.6em;
				}
	
				> .label {
					max-width: 400px;
					color: var(--guiding-star-color);
				}
			}
		}
		
		> .tabs {
			display: flex;
			flex-shrink: 0;
			width: 100%;
			max-width: 800px;
			padding: 10px;
			box-sizing: border-box;
			margin: 0 auto;
	
			> button.tab {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-grow: 1;
				margin: 5px;
				padding: 12px 10px;
	
				&:not(:first-child) {
					margin-inline-start: 0;
				}
	
				> .icon {
					margin-inline-end: 12px;
					font-size: 50px;
				}
	
				> .label {
					font-size: 18px;
					text-align: left;
				}
			}
		}
	
		> .stacks {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			flex-grow: 1;
			height: auto;
			max-width: 800px;
			padding: 10px;
			box-sizing: border-box;
			margin: 0 auto;
			overflow-x: hidden;
			overflow-y: auto;
	
			> .stack-option {
				flex-shrink: 0;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				margin-top: 10px;
				padding: 5px;
				border-radius: 18px;
				animation: blur-in 0.9s $ease-out-quint 1s backwards;
	
				$i: 0;
				@while $i < 10 {
					&:nth-child(#{$i + 1}) {
						animation-delay: 0.1s + $i * 0.1s;
					}
					$i: $i + 1;
				}
	
				&:hover {
					background-color: var(--soft-background-hover);
				}
	
				&:active {
					background-color: var(--soft-background-active);
				}
	
				> .StackSlideshowPreview {
					flex-shrink: 0;
					font-size: 15px;
				}
	
				> .details {
					margin-inline-start: 10px;
					padding: 15px;
	
					> .name {
						font-size: 24px;
						font-weight: 500;
					}
	
					> .description {
						margin-top: 15px;
						font-size: 18px;
						line-height: 1.4em;
						color: var(--color-soft);
					}
				}
			}
		}
	}
}

.App.large-ui .StackCreate {
	> .container > .guiding-star-hint > .container {
		> .GuidingStar {
			font-size: 40px;
		}

		> .label {
			font-size: 18px;
			line-height: 1.25em;
		}
	}
}

.App.small-ui .StackCreate {
	> .container {
		> .guiding-star-hint > .container {
			> .GuidingStar {
				font-size: 34px;
			}
	
			> .label {
				font-size: 17px;
			}
		}

		> .tabs {
			> button.tab {
				> .icon {
					font-size: 36px;
				}
		
				> .label {
					font-size: 16px;
				}
			}
		}
	}
}