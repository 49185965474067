@import "general";
@import "animations";

.RichTextInput {
	> .toolbar {
		pointer-events: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 2px;
		margin-left: -5px;
		margin-right: -5px;
		opacity: 0.3;
		transition: opacity 0.5s $ease-out-quint;

		button {
			cursor: pointer;
			background: transparent;
			border: none;
			padding: 0;
			margin: 0 1px;
			height: 28px;
			width: 30px;
			font-size: 20px;
			font-weight: 500;
			border-radius: 6px;
			text-align: center;
			color: var(--color-softer);

			@media (max-width: 350px) {
				margin: 0;
			}

			&.active {
				color: var(--color-selected);
				text-shadow:
					0 0 2px var(--color-selected-neon),
					0 1px 5px var(--color-selected-neon);
			}

			> .icon {
				pointer-events: none;
			}

			&:hover {
				background: var(--color-panel-face-hover);
			}

			&:active {
				background: var(--color-panel-face-active);
			}
		}
	}

	> .DraftEditor-root {
		pointer-events: none;
		margin-left: calc(-1 * var(--inspector-padding-h));
		margin-right: calc(-1 * var(--inspector-padding-h));
		margin-bottom: calc(-1 * var(--inspector-padding-v));
		border-top: 1px solid var(--color-textbox-border);
		border-bottom-left-radius: var(--inspector-border-radius);
		border-bottom-right-radius: var(--inspector-border-radius);
		color: var(--color-textbox-face);
		background: var(--color-textbox-surface);
		opacity: 0.85;
		transition: opacity 0.5s $ease-out-quint;

		.public-DraftEditorPlaceholder-root {
			color: var(--color-soft);
			padding: calc(var(--inspector-padding-v) - 1px) var(--inspector-padding-h) var(--inspector-padding-v);
		}

		.DraftEditor-editorContainer {
			max-height: 300px;
			overflow-y: auto;

			.public-DraftEditor-content {
				padding: calc(var(--inspector-padding-v) - 10px) var(--inspector-padding-h) var(--inspector-padding-v);
				pointer-events: auto;
			}
		}

		&, div, span {
			@include text-selectable;
			cursor: text;
		}

		.public-DraftStyleDefault-block {
			margin-top: 0.5em;
			font-size: 15px;
			line-height: 1.4em;
		}

		.public-DraftStyleDefault-ul,
		.public-DraftStyleDefault-ol {
			line-height: 1.2em;

			.public-DraftStyleDefault-block {
				line-height: inherit;
			}
		}

		.public-DraftStyleDefault-unorderedListItem {
			margin-top: 0.25em;
		}

		.public-DraftStyleDefault-orderedListItem {
			margin-top: 0.25em;
		}

		h2 .public-DraftStyleDefault-block {
			font-size: 22px;
		}

		h3 .public-DraftStyleDefault-block {
			font-size: 19px;
		}
		
		blockquote {
			margin-left: 1em;
			padding-left: 1em;
			border-left: 2px solid var(--color-hint);
		}
	}

	&.focus {
		> .toolbar,
		> .DraftEditor-root {
			opacity: 1;
		}

		> .toolbar,
		> .DraftEditor-root .DraftEditor-editorContainer {
			pointer-events: auto;
		}
	}
}