@import "animations";

.GuidingStar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1em;
	height: 1em;
	font-size: 20px;
	color: var(--guiding-star-color);

	> .icon {
		position: absolute;
		animation: guiding-star-beat 2s $ease-out-quint infinite;
	}

	> .icon.glow {
		filter: blur(2px);
		animation: guiding-star-glow 2s $ease-out-quint infinite;
	}
}

@keyframes guiding-star-beat {
	from, 80%, to {
		transform: scale(1);
	}
	15% {
		transform: scale(1.1);
	}
}

@keyframes guiding-star-glow {
	from {
		transform: scale(0.9);
		opacity: 1;
	}
	to {
		transform: scale(2);
		opacity: 0;
	}
}