@import "animations";
@import "colors";

.TextAreaInput {
	display: block;
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
	min-height: 10em;
	max-height: 20em;
	padding: 0.3em 0.6em;
	margin: 0;
	border: 1px solid var(--color-textbox-border);
	border-radius: 4px;
	font: inherit;
	color: var(--color-textbox-face);
	background: var(--color-textbox-surface);
	line-height: 1.5em;

	&::placeholder {
		color: var(--color-panel-face-placeholder);
	}
	&::-moz-placeholder {
		color: var(--color-panel-face-placeholder);
	}
	&::-webkit-input-placeholder {
		color: var(--color-panel-face-placeholder);
	}
}

@media (min-width: 1110px) {
	.Inspector .TextAreaInput {
		height: 5.2em;
		min-height: 5.2em;
	}
}