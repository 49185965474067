@keyframes fade-in {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes fade-to {
	0% {
		opacity: 0; } }

@keyframes fade-out {
	0% {
		opacity: 1; }
	100% {
		opacity: 0; } }

@keyframes blur-in {
	from {
		opacity: 0;
		filter: blur(5px);
		transform: scale(0.97); }
	to {
		opacity: 1;
		filter: blur(0);
		transform: scale(1); } }

@keyframes blur-out {
	from {
		opacity: 1;
		filter: blur(0);
		transform: scale(1); }
	to {
		opacity: 0;
		filter: blur(4px);
		transform: scale(0.98); } }



/*** Easings ***/

$ease-snappy: cubic-bezier(0.3, 0.05, 0.11, 1);

// Cubic.
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// Quint.
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

// Expo.
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);

// Rubber band easing at the end of the animation.
$ease-rubber-band-in: cubic-bezier(0.58, 1.30, 0.55, 0.97);
$ease-sharp-rubber-band-in: cubic-bezier(0.5, 1.54, 0.56, 0.99);
$ease-short-rubber-band-in: cubic-bezier(0.6, 1.34, 0.56, 0.99);

// Rubber band easing at the beginning of the animation.
$ease-rubber-band-out: cubic-bezier(0.35, -0.7, 0.39, 1);
