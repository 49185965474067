@import "animations";

.StackEmbed {
	position: relative;
	background: var(--color-panel-face);
	border-radius: 20px;
	padding: 8px;
	width: 350px;
	height: 600px;
	margin: 10px;
	box-shadow: var(--floating-object-shadow);

	> .container {
		width: 100%;
		height: inherit;
		border-radius: 12px;
		overflow: hidden;

		> iframe {
			width: inherit;
			height: inherit;
			transition: opacity 0.5s $ease-out-expo;
		}
	}

	&.loading > .container > iframe {
		opacity: 0;
	}
}

.App.large-ui .Creator {
	.StackEmbed {
		> .exit-fullscreen {
			display: none;
		}

		@media (max-height: 690px) {
			top: -10px;
			height: 550px;
			padding: 1px;
			border-radius: 0;

			> .container {
				border-radius: none;
			}
		}
	}
}

.App.small-ui .Creator {
	.StackEmbed {
		position: fixed;
		right: 10px;
		top: 10px;
		z-index: 120;
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100 - 50px);
		margin: 0;
		transform: scale(0.33);
		transform-origin: top right;
		transition: 0.7s $ease-out-expo;
		transition-property: top, right, opacity, transform;

		iframe {
			pointer-events: none;
		}

		> .exit-fullscreen {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100vw;
			height: 0;
			border: none;
			padding: 0;
			margin: 0;
			background: hsl(46, 91%, 60%);
			color: hsl(9, 100%, 29%);
			opacity: 0;
			font-size: 18px;
			font-weight: 500;
			transition: 0.7s $ease-out-expo;
			transition-property: height, opacity;

			&:active {
				background: hsl(41, 83%, 54%);
			}
		}
	}

	&.view-inspector .StackEmbed.fullscreen {
		right: 0px;
		top: 0px;
		padding: 0px;
		border-radius: none;
		transform: scale(1);

		iframe {
			pointer-events: auto;
		}

		> .exit-fullscreen {
			height: 50px;
			opacity: 1;
		}
	}

	&.view-stack .StackEmbed,
	&.pip-hide .StackEmbed {
		opacity: 0;
		pointer-events: none;
		transform: translateX(50vw) scale(0.33);
	}
}