@import "animations";

.ShareOptions {
	padding: 20px;

	> .label {
		font-size: 16px;
		margin-bottom: 8px;

		&:not(:first-child) {
			margin-top: 25px;
		}
	}

	> .qr-code {
		position: relative;
		width: 150px;
		height: 150px;

		> .QRCode {
			width: inherit;
			height: inherit;
			user-select: auto;
			-webkit-user-drag: auto;
			animation: fade-in 0.3s $ease-snappy;
			border-radius: 12px;

			img {
				cursor: pointer;
			}
		}
	}
}