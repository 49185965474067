@import "animations";
@import "colors";

.TextInput {
	$text-input-height: 32px;
	$textbox-border-radius: 6px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: $text-input-height;
	font-size: 15px;

	.value {
		position: relative;
		width: inherit;
		height: inherit;

		> input {
			display: block;
			box-sizing: border-box;
			width: inherit;
			height: inherit;
			padding: 0 0.6em 1px;
			margin: 0;
			border: 1px solid var(--color-textbox-border);
			border-radius: $textbox-border-radius;
			font: inherit;
			transition: 0.3s $ease-out-cubic;
			transition-property: width, margin-left;
			color: var(--color-textbox-face);
			background: var(--color-textbox-surface);
			outline: none !important;

			&::placeholder {
				color: var(--color-soft);
			}
			&::-moz-placeholder {
				color: var(--color-panel-face-placeholder);
			}
			&::-webkit-input-placeholder {
				color: var(--color-panel-face-placeholder);
			}

			&:focus {
				border-color: var(--color-selected);
			}
		}

		> .action {
			display: flex;
			flex-direction: row;
			justify-content: center;
			$padding: 2px;
			$size: $text-input-height - $padding * 2;
			position: absolute;
			right: $padding;
			top: $padding;
			width: $size;
			height: $size;
			border-radius: 3px;
			background: var(--color-textbox-surface);
			font-size: 12px;
			cursor: pointer;

			&.clear {
				color: var(--color-panel-face-quarter);

				&:hover,
				&:active {
					color: var(--color-panel-face-half);
				}
			}

			&:active {
				color: var(--color-selection);
			}
		}
	}

	&.disabled {
		input {
			color: var(--color-panel-face-disabled);
			background-color: var(--color-textbox-surface-disabled);
		}
	}

	&.auto-expand.editing {
		.value > input:focus {
			width: 258px;
			margin-left: -138px;
			text-align: center;
		}
	}

	> .suggestions {
		position: absolute;
		display: block;
		left: auto;
		right: -5px;
		top: $text-input-height + 2px;
		z-index: 10;
		// min-width: $text-input-width;

		> .item {
			position: relative;
			padding: 3px 30px 3px 11px;
			color: var(--color-panel-face);
			text-align: left;

			&.checked::after {
				content: "\e90c";
				font-family: 'seatsdesigner';
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -0.7em;
				right: 6px;
				color: var(--color-panel-face-half);
			}

			&:hover:not(:disabled),
			&.selected:not(:disabled) {
				color: var(--color-textbox-surface);
				background: var(--color-selection);

				&.checked::after {
					color: rgba(255, 255, 255, .5);
				}
			}

			&:disabled {
				color: var(--color-panel-face-disabled);
			}
		}
	}
}