.CardPreview {
	position: relative;
	width: 10em;
	height: 14em;
	padding: 0.8em;
	background: var(--card-background);
	border-radius: 1em;
	font-size: 10px;
	color: var(--color);
	box-sizing: border-box;
	box-shadow: 0 7px 15px -10px hsla(0, 20%, 4%, 0.5);
	overflow: hidden;

	&.with-bottom-fade::after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: 1.5em;
		background: linear-gradient(to top, var(--card-background), var(--card-background-transparent) 100%);
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}

	> .clickable-surface {
		cursor: pointer;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		&.full-size-centered {
			display: flex;
			flex-direction: vertical;
			justify-content: center;
			align-items: center;

			> .plus {
				font-size: 60px;
				font-weight: 300;
			}
		}
	}

	.plate {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		opacity: 0.7;
	}

	.title {
		width: 100%;
		height: 1.2em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		word-wrap: break-word;
		font-weight: bold;
	}

	.SmartImage {
		width: 100%;
		height: 5em;
		margin-top: 0.8em;
		border-radius: 0.5em;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&.images .SmartImage {
		height: 10.3em;
	}

	.body {
		position: relative;
		width: 100%;
		max-height: 2.5em;
		padding-bottom: 1em;
		margin-top: 1em;
		overflow: hidden;
		color: var(--color-soft);

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			height: 1.5em;
			background: linear-gradient(to top, var(--card-background), var(--card-background-transparent) 100%);
		}
	}

	.items {
		margin-top: 1em;
		color: var(--color-soft);

		.item {
			width: 100%;
			height: 1em;
			padding: 2px 0 4px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-wrap: break-word;

			& + .item {
				border-top: 1px solid var(--color-panel-face-quarter);
			}
		}
	}

	.body + .items {
		margin-top: 0;
	}

	.definitions {
		margin-top: 1em;
		color: var(--color-soft);

		.item {
			padding-bottom: 2px;

			> dt,
			> dd {
				display: block;
				width: 100%;
				height: 1em;
				margin: 0;
				padding: 2px 0 4px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				word-wrap: break-word;
			}

			> dd {
				font-size: 12px;
			}

			& + .item {
				border-top: 1px solid var(--color-panel-face-quarter);
			}
		}
	}

	@media (prefers-color-scheme: light) {
		&.clickable:hover {
			filter: brightness(1.02);
		}

		&.clickable:active {
			filter: brightness(0.96);
		}
	}

	@media (prefers-color-scheme: dark) {
		&.clickable:hover {
			filter: brightness(1.10);
		}

		&.clickable:active {
			filter: brightness(0.88);
		}
	}
}