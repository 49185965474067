@import "animations";

.PageView {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	background-image: url("/textures/noise-100-2.png");
	background-position: center center;
	background-repeat: repeat;
	background-size: 50px 50px;
	animation: fade-in 1.2s $ease-snappy;

	> .spotlight {
		position: absolute;
		left: -25vw;
		top: -25vw;
		width: 150vw;
		height: 150vw;
		margin-left: -75vw;
		margin-top: -75vw;
		transition: opacity 0.4s $ease-snappy;
	}

	> .footer {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 30px;
	}

	&.scene-transitioning {
		> .spotlight {
			opacity: 0;
		}
	}

	@media (prefers-color-scheme: light) {
		background-color: hsl(210, 1%, 60%);

		> .spotlight {
			background: radial-gradient(75vw 75vw at center, hsla(45, 1%, 100%, 0.6), hsla(0, 0%, 100%, 0.35), hsla(0, 0%, 100%, 0));
		}
	}

	@media (prefers-color-scheme: dark) {
		background-color: hsl(210, 1%, 9%);

		> .spotlight {
			background: radial-gradient(75vw 75vw at center, hsla(45, 1%, 70%, 0.35), hsla(180, 1%, 60%, 0.12), hsla(0, 0%, 0%, 0));
			background-position: right top;

			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-image: url("/textures/noise-100-2.png");
				background-position: center center;
				background-repeat: repeat;
				background-size: 50px 50px;
			}
		}
	}

	> .dialog {
		position: relative;
		z-index: 1;
		max-width: 350px;

		.title {
			font-size: 22px;
			text-align: center;
		}

		.hint {
			font-size: 14px;
			opacity: 0.6;
			line-height: 1.4em;
			margin-top: 1em;
			text-align: center;
		}

		&,
		> form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		label {
			font-size: 18px;
			opacity: 0.7;
		}

		.acrylic-input {
			width: 280px;
			margin: 20px 0;
		}
	}

	.acrylic-input {
		position: relative;
		border-radius: 10px;

		> input {
			position: relative;
			z-index: 1;
			font-size: 18px;
			border-radius: 10px;
			height: inherit;
			width: inherit;
			border: none;
			margin: 0;
			box-sizing: border-box;
			background: none;
			padding: 9px 14px;
			border-radius: inherit;
			outline: none;

			&:focus {
				box-shadow:
					0 0 0 1px var(--color-selected),
					inset 0 0 0 1px var(--color-selected),
					0 0 3px var(--color-selected),
					inset 0 0 3px var(--color-selected);
			}
		}

		.cast-shadow {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: inherit;
		}

		&.digits-input {
			width: 13em;
			margin-left: auto;
			margin-right: auto;

			> input {
				padding-left: 1.4em;
				padding-right: 0;
				letter-spacing: 1em;
			}
		}

		@media (prefers-color-scheme: light) {
			background-color: hsla(0, 0%, 100%, 0.5);
			box-shadow: inset 0 0 3px hsla(0, 0%, 0%, 0.3);
			> input { color: black }

			.cast-shadow {
				background-color: hsla(0, 0%, 90%, 0.30);
				box-shadow: 0 0 5px hsla(0, 0%, 90%, 0.70);
			}
		}
	
		@media (prefers-color-scheme: dark) {
			background-color: hsla(0, 0%, 60%, 0.25);
			box-shadow: inset 0 0 2px hsla(0, 0%, 60%, 0.5);
			> input { color: white }

			.cast-shadow {
				background-color: hsla(0, 0%, 0%, 0.12);
				box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.25);
			}
		}
	}

	.acrylic-button {
		position: relative;
		border-radius: 10px;
		height: 36px;
		
		> button,
		> input[type="submit"] {
			cursor: pointer;
			appearance: none;
			display: inline-block;
			position: relative;
			z-index: 1;
			font-size: 18px;
			border-radius: 10px;
			border: none;
			height: inherit;
			width: inherit;
			font-size: 100%;
			font-family: inherit;
			box-sizing: border-box;
			background: linear-gradient(to top, hsl(218, 82%, 50%) 10%, hsl(218, 88%, 56%));
			color: hsl(184, 100%, 92%);
			padding: 9px 14px;
			border-radius: inherit;
			transition: filter 0.3s $ease-snappy;
			outline: none !important;

			&:hover {
				filter: brightness(1.11);
			}

			&:active {
				filter: brightness(0.9) contrast(1.1);
			}
		}

		.cast-shadow {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: inherit;
		}

		@media (prefers-color-scheme: light) {
			box-shadow: 0 0 10px hsla(218, 82%, 50%, 0.4);

			.cast-shadow {
				background-color: hsla(200, 100%, 60%, 0.35);
				box-shadow: 0 0 10px hsla(200, 100%, 60%, 0.75);
			}
		}
	
		@media (prefers-color-scheme: dark) {
			box-shadow: 0 0 10px hsla(218, 82%, 50%, 0.4);

			.cast-shadow {
				background-color: hsla(234, 100%, 40%, 0.42);
				box-shadow: 0 0 10px hsla(234, 100%, 40%, 0.75);
			}
		}
	}

	.dialog-elements-enter {
		opacity: 0;

		&.dialog-elements-enter-active {
			opacity: 1;
			transition: opacity 0.5s $ease-out-cubic;
		}
	}

	.dialog-elements-exit {
		opacity: 1;

		&.dialog-elements-exit-active {
			opacity: 0;
			transition: opacity 0.4s $ease-out-quint;
		}
	}

	> .acrylic-logo {
		position: absolute;
		left: 50%;
		top: 30px;
		margin-left: -140px;
		width: 280px;
		height: 110px;

		> .logo,
		> .cast-shadow {
			position: absolute;
			left: 0;
			top: 0;
			width: inherit;
			height: inherit;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;

			@media (prefers-color-scheme: light) {
				background-image: url("/stacky-black.png");
			}

			@media (prefers-color-scheme: dark) {
				background-image: url("/stacky-white.png");
			}
		}

		> .cast-shadow {
			filter: blur(3px) opacity(0.2);
		}
	}
}