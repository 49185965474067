@import "animations";

.ToggleInput {
	position: relative;

	> .center-overlay {
		align-items: flex-start;
		padding: 0 12px;
	}

	.LoadingIndicator {
		font-size: 20px;
		opacity: 0;
		transition: opacity 0.3s $ease-out-quint;
	}

	> .input {
		cursor: pointer;
		padding: 6px 12px;
		border-radius: 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: opacity 0.9s $ease-out-quint;

		> input,
		> label {
			pointer-events: none;
		}

		> input {
			flex-shrink: 0;
			margin: 0;
		}
	
		> label {
			position: relative;
			top: -1px;
			flex-grow: 1;
			margin: 0 0 0 10px;
			color: var(--color-soft);
			font-size: 15px;
		}
	
		&:hover {
			background: var(--soft-background-hover);
		}
	
		&:active {
			background: var(--soft-background-active);
		}
	}

	&.loading {
		.LoadingIndicator {
			opacity: 1;
		}

		> .input {
			cursor: default;
			pointer-events: none;
			opacity: 0;
			transition-duration: 0.3s;
		}
	}
}