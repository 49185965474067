@import "animations";

.StateButton {
	position: relative;

	.LoadingIndicator {
		font-size: 20px;
		opacity: 0;
	}

	.LoadingIndicator {
		transition: opacity 0.3s $ease-out-quint;
	}

	> .button {
		transition: opacity 0.9s $ease-out-quint;
	}
	
	button {
		appearance: none;
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		outline: none !important;
		color: inherit;
		font-size: inherit;
	}

	&.loading {
		.LoadingIndicator {
			opacity: 1;
		}

		> .button {
			opacity: 0;
			transition-duration: 0.3s;
		}
	}
}