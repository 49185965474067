@import "animations";

.ClipboardInput {
	cursor: pointer;
	position: relative;
	width: 100%;
	height: 50px;
	background-color: var(--color-panel-inset);
	transition: 0.15s $ease-snappy;
	transition-property: background-color, box-shadow;
	border-radius: 12px;

	> .label {
		pointer-events: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		box-sizing: border-box;
		padding-left: 10%;
		padding-right: 10%;
		color: var(--color);
		text-align: center;
		font-size: 17px;
		line-height: 1.4em;
	}

	&:hover {
		box-shadow: inset 0 0 0 2px var(--color-panel-face-half);
	}

	&:active {
		box-shadow: inset 0 0 0 3px var(--color-panel-face-half);
		background-color: var(--color-panel-inset-deeper);
	}
}