@import "general";
@import "animations";

.SmartImage {
	@include solidify;
	display: block;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: color(--card-background);
	transform: translate3d(0, 0, 0);

	> .image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		filter: blur(0) opacity(1);
		transition: filter 0.5s $ease-snappy;
	}

	&.loading {
		> .image {
			filter: blur(3px) opacity(0);
		}
	}
}
