/* Commons */

body {
	--color-alert-danger: #db0e11;
}


/* Light color scheme */

@media (prefers-color-scheme: light) {
	body {
		--color-selected: hsl(211, 100%, 52%);
		--color-selected-highlight: hsl(209, 100%, 55%);
		--color-selected-deep: hsl(217, 100%, 40%);
		--color-selected-neon: hsla(236, 100%, 50%, 0.1);

		--color: hsl(0, 0%, 10%);
		--color-soft: hsl(0, 0%, 30%);
		--color-softer: hsl(0, 0%, 42%);
		--color-hint: hsl(0, 0%, 60%);
		--color-contrast-text-shadow: hsla(0, 0%, 95%, 0.7);
		--color-panel-face: hsl(0, 0%, 97%);
		--color-panel-face-shade: hsl(0, 0%, 92%);
		--color-panel-face-placeholder: hsl(0, 0%, 66%);
		--color-panel-face-half: hsl(0, 0%, 75%);
		--color-panel-face-quarter: hsl(0, 0%, 88%);
		--color-panel-face-disabled: hsl(0, 0%, 80%);
		--color-panel-face-hover: hsl(0, 0%, 88%);
		--color-panel-face-active: hsl(0, 0%, 85%);
		--color-panel-inset: hsl(0, 0%, 90%);
		--color-panel-inset-hover: hsl(0, 0%, 88%);
		--color-panel-inset-deeper: hsl(0, 0%, 85%);
		--color-textbox-face: hsl(0, 0%, 15%);
		--color-textbox-surface: white;
		--color-textbox-surface-disabled: #fcfcfc;
		--color-textbox-border: hsla(0, 0%, 30%, 0.2);
		--color-textbox-border-focus: hsla(0, 0%, 30%, 0.3);

		--card-background: hsl(0, 0%, 98%);
		--card-background-soft: hsl(0, 0%, 92%);
		--card-background-transparent: hsla(0, 0%, 95%, 0);
		--background: hsl(210, 2%, 79%);
		--modal-background: hsl(210, 2%, 52%);
		--context-button-background: hsl(0, 0%, 88%);

		--soft-background: hsla(0, 0%, 10%, 0.07);
		--soft-background-hover: hsla(0, 0%, 10%, 0.1);
		--soft-background-active: hsla(0, 0%, 10%, 0.15);

		--guiding-star-color: hsl(39, 98%, 48%);
	}
}


/* Dark color scheme */


@media (prefers-color-scheme: dark) {
	body {
		--color-selected: hsl(214, 100%, 56%);
		--color-selected-highlight: rgb(20, 161, 255);
		--color-selected-deep: hsl(214, 100%, 50%);
		--color-selected-neon: hsl(219, 100%, 30%);

		--color: hsl(0, 0%, 95%);
		--color-soft: hsl(0, 0%, 75%);
		--color-softer: hsl(0, 0%, 63%);
		--color-hint: hsl(0, 0%, 45%);
		--color-contrast-text-shadow: hsla(0, 0%, 5%, 0.8);
		--color-panel-face: hsl(180, 2%, 19%);
		--color-panel-face-shade: hsl(180, 2%, 25%);
		--color-panel-face-placeholder: hsl(180, 1%, 55%);
		--color-panel-face-half: hsl(180, 1%, 50%);
		--color-panel-face-quarter: hsl(180, 2%, 25%);
		--color-panel-face-disabled: hsl(180, 2%, 30%);
		--color-panel-face-hover: hsl(180, 2%, 15%);
		--color-panel-face-active: hsl(180, 2%, 10%);
		--color-panel-inset: hsl(210, 2%, 15%);
		--color-panel-inset-hover: hsl(210, 2%, 13%);
		--color-panel-inset-deeper: hsl(210, 2%, 10%);
		--color-textbox-face: hsl(0, 0%, 90%);
		--color-textbox-surface: hsl(0, 0%, 30%);
		--color-textbox-surface-disabled: hsl(0, 0%, 40%);
		--color-textbox-border: hsla(0, 0%, 35%, 0.12);

		--card-background: hsl(0, 0%, 0%);
		--card-background-soft: hsl(0, 0%, 5%);
		--card-background-transparent: hsla(0, 0%, 0%, 0);
		--background: hsl(180, 2%, 10%);
		--modal-background: hsl(180, 2%, 5%);
		--context-button-background: hsl(0, 0%, 20%);

		--soft-background: hsla(0, 0%, 95%, 0.07);
		--soft-background-hover: hsla(0, 0%, 95%, 0.09);
		--soft-background-active: hsla(0, 0%, 95%, 0.12);

		--guiding-star-color: hsl(39, 100%, 66%);
	}
}