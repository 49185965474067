@import "animations";

.StackPreview.StackSlideshowPreview {
	position: relative;
	width: 10em;
	height: 14em;
	overflow: hidden;
	font-size: 10px;
	border-radius: 1em;
	box-shadow: 0 7px 15px -10px hsla(0, 20%, 4%, 0.5);
	margin: 0;
	padding: 0;
	transform: translateZ(0);

	> .preview {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		transition: left 1.2s $ease-out-quint;

		> .CardPreview {
			flex-shrink: 0;
			font-size: inherit;
			border-radius: 0;
			box-shadow: none;
		}
	}
}