@import "animations";

.CardList {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	box-sizing: border-box;

	.CardPreview {
		flex-shrink: 0;
	}

	.card-item {
		position: relative;
		cursor: pointer;
		display: flex;
		flex-shrink: 0;

		> .GuidingStar {
			position: absolute;
			top: 15px;
			right: 15px;
		}

		> button.more {
			pointer-events: none;
			cursor: pointer;
			position: absolute;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			bottom: 45px;
			right: 5px;
			border-radius: 1em;
			width: 1.8em;
			height: 1.8em;
			border: none;
			font-size: 15px;
			background: var(--context-button-background);
			opacity: 0;
			color: var(--color);

			&:hover {
				color: var(--color-selected);
			}

			&:active {
				color: white;
				background-color: var(--color-selected-deep);
			}

			&.active {
				color: white;
				background-color: var(--color-selected);
			}
		}

		&:hover {
			> button.more {
				pointer-events: auto;
				opacity: 1;
			}
		}

		&:not(:hover) > button.more.active {
			opacity: 1;
		}

		&.selected {
			.CardPreview {
				box-shadow: 0 0 0 4px var(--color-selected);
			}
		}
	}

	&.animate-in {
		.card-item {
			animation: blur-in 0.6s $ease-out-quint 1s backwards;

			$i: 0;
			@while $i < 10 {
				&:nth-child(#{$i + 1}) {
					animation-delay: $i * 0.1s;
				}
				$i: $i + 1;
			}
		}
	}

	&.thumbnails {
		padding: 20px;
		width: 140px;

		.card-item {
			flex-direction: column;
			height: 182px;
			
			> .name {
				width: 100%;
				margin-top: 5px;
				pointer-events: none;
				font-size: 13px;
				text-align: center;
				font-weight: 500;
				color: var(--color-soft);
				opacity: 0.6;

				> .icon {
					margin-right: 0.3em;
				}
			}

			&.selected {
				> .name {
					opacity: 1;
				}
			}
		}
	}

	&.list {
		padding: 5px;
		width: 400px;

		.card-item {
			padding: 8px;
			flex-direction: row;
			background: var(--color-panel-face);
			border-radius: 16px;

			&:hover {
				background: var(--color-panel-face-hover);
			}

			&.selected,
			&:active {
				background: var(--color-panel-face-active);
			}

			& + .card-item {
				margin-top: 2px;
			}

			> .description {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				padding: 8px 10px 8px 20px;
				pointer-events: none;
				opacity: 0.85;

				> .name {
					color: var(--color);
					font-size: 18px;
					font-weight: 500;
					transition: opacity 0.5s $ease-out-quint;
				}

				> .info {
					margin-top: 6px;
					color: var(--color-soft);
					font-size: 15px;
					line-height: 1.4em;
				}
			}

			&.selected {
				> .description {
					opacity: 1;
				}
			}
		}
	}

	> .DraggableList > .DraggableListItem {
		border: none;

		> .handle-bar {
			touch-action: none;
			position: absolute;
			top: 0;
			bottom: 30px;
			left: -20px;
			width: 30px;
			padding: 0;
			background: transparent;
			flex-direction: column;
			justify-content: center;
			opacity: 0;
			transition: opacity 0.5s $ease-out-quint;
			
			> .handle {
				font-size: 12px;
			}
		}

		> .actions {
			pointer-events: none;
			z-index: 1;
			top: 107px;
			right: 5px;
			padding: 0;
			border-radius: 6px;
			opacity: 0;
			transform: scale(0.5);
			transition: 0.5s $ease-out-quint;
			transition-property: opacity, transform;
			background: var(--color-panel-face);

			> .action {
				padding: 6px 7px;
			}

			&.force-show {
				pointer-events: auto;
				opacity: 1;
				transform: scale(1);
			}
		}

		&:hover > .handle-bar {
			opacity: 1;
			transition-delay: 0.3s;
		}

		&.dragged {
			background: transparent;
			box-shadow: none;

			> .handle-bar,
			> .actions {
				opacity: 0;
			}
		}
	}
}

.App.large-ui .Creator .CardList {
	> .DraggableList > .DraggableListItem {
		&.dragged {
			.name {
				opacity: 0;
			}
		}
	}
}

.App.small-ui .Creator .CardList {
	&.thumbnails {
		padding-left: 60px;
	}

	> .DraggableList > .DraggableListItem {
		> .handle-bar {
			left: -38px;
			bottom: 0;
			opacity: 0.7;

			> .handle {
				font-size: 22px;
			}

			> .title {
				display: none;
			}
		}

		> .card-item {
			touch-action: auto;
			flex-direction: row;
			height: 140px;
			margin-bottom: 20px;
			border-radius: 12px;
			background: var(--background);

			> .name {
				padding-top: 10px;
				font-size: 18px;
				text-align: left;
				padding-left: 30px;
			}

			&:active {
				background: var(--soft-background);
			}
		}

		> .actions {
			pointer-events: auto;
			top: auto;
			right: auto;
			left: 130px;
			bottom: 15px;
			opacity: 1;
			font-size: 20px;
			transform: none;

			> .action {
				padding: 10px 16px;
			}
		}
		
		&.dragged {
			> .actions {
				opacity: 0;
			}
		}
	}
}