@import "animations";

.Popup {
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.25s $ease-snappy;
	z-index: 1000;

	&,
	> .backdrop {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	&.visible {
		pointer-events: auto;
		opacity: 1;

		> .container {
			animation: popup-in 0.4s $ease-out-quint 0.05s backwards;
		}
	}

	&.fast.visible {
		> .container {
			animation-duration: 0.2s;
			animation-delay: 0;
		}
	}

	> .backdrop {
		background: var(--modal-background);
		opacity: 0.84;
	}

	> .container {
		position: absolute;
		overflow: hidden;

		> .popup-component-support {
			background: var(--color-panel-face);
			border-radius: 20px;
		}

		> .CardList {
			height: 100%;
			overflow-y: auto;
			padding-right: 20px;
		}

		@media (prefers-color-scheme: light) {
			box-shadow: 0 10px 30px -15px hsla(0, 0%, 0%, 1);
		}
	
		@media (prefers-color-scheme: dark) {
			box-shadow: 0 10px 30px -15px hsla(0, 0%, 0%, 1);
		}
	}
}

.App.small-ui .Popup {
	> .container {
		top: 100px;
		right: 10px;
		bottom: 10px;

		> .CardList.list {
			width: calc(100vw - 20px);
		}
	}
}

@keyframes popup-in {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}