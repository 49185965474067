@import "animations";
@import "effects";

.StackPreview {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	padding: 0 20px;
	width: 170px;
	height: 300px;
	margin-top: 20px;
	border-radius: 20px;

	> .preview {
		position: relative;
		border-radius: 14px;
		box-shadow: 0 0 13px -2px rgba(255, 255, 255, 0);
		transition: 2s $ease-out-quint;
		transition-property: box-shadow;

		> .CardPreview {
			flex-shrink: 0;
			font-size: 15px;
			margin-bottom: 5px;
			box-shadow: var(--floating-object-shadow);

			&:not(.faux) {
				z-index: 1;
			}

			&.faux {
				position: absolute;
				bottom: 0;
				background-color: var(--card-background-soft);
				transform: rotate(-6deg);
				transition: transform 0.6s $ease-out-expo;
				box-shadow: none;

				&:last-child {
					transform: rotate(-14deg);
				}
			}
		}

		> .glow {
			pointer-events: none;
			position: absolute;
			left: 0;
			top: 40px;
			right: 0;
			bottom: 40px;
			opacity: 0;
			z-index: -1;
			transform: scale(1);
			transition: 1.4s $ease-out-quint;
			transition-property: transform, opacity;

			@media (prefers-color-scheme: light) {
				background: radial-gradient(50% 50% at center, hsla(187, 70%, 95%, 1) 50%, hsla(200, 70%, 95%, 0.6) 65%, hsla(230, 70%, 95%, 0.2) 80%, hsla(0, 0%, 90%, 0) 100%);
			}

			@media (prefers-color-scheme: dark) {
				background: radial-gradient(50% 50% at center, hsla(200, 70%, 80%, 1) 50%, hsla(210, 70%, 70%, 0.3) 65%, hsla(230, 60%, 60%, 0.1) 80%, hsla(230, 30%, 40%, 0) 100%);
			}
		}

		&:hover {
			transition-delay: 0.1s;

			@media (prefers-color-scheme: light) {
				box-shadow: 0 0 13px -2px hsla(203, 100%, 97%, 1);
			}

			@media (prefers-color-scheme: dark) {
				box-shadow: 0 0 13px -2px hsla(203, 100%, 89%, 0.7);
			}

			> .glow {
				opacity: 1;
				transform: scale(2);
				transition-delay: 0.1s;
			}
			
			> .CardPreview {
				&.faux {
					transform: rotate(-10deg);
	
					&:last-child {
						transform: rotate(-22deg);
					}
				}
			}
		}

		&:active {
			> .glow {
				filter: brightness(0.8);
			}
		}
	}

	.StateButton .name {
		cursor: pointer;
		color: var(--color);
		padding-left: 1.4em;
		padding-top: 0.8em;
		padding-bottom: 0.8em;

		> .value {
			transition: all 0.2s $ease-out-quint;
			font-size: 16px;
		}

		> .icon {
			display: inline-block;
			margin-left: 0.4em;
			opacity: 0.25;
			transition: 0.3s $ease-out-quint;
			transition-property: opacity, transform, color;
			transform-origin: left bottom;
		}

		&:active {
			color: var(--color-selected-deep);
		}
	}

	.lastModified {
		cursor: default;
		color: var(--color-softer);
		padding: 0 22px;
		font-size: 14px;
	}

	&:not(.placeholder) {
		.StateButton .name {
			&:hover,
			&:active {
				color: var(--color-selected);

				> .icon {
					color: inherit;
					opacity: 1;
					transform: scale(1);
				}
			}

			&:active {
				color: var(--color-selected-deep);
			}
		}
	}

	&.placeholder {
		.StateButton .name {
			pointer-events: none;
		}
	}

	> .definitions {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}