.App {
	@media (prefers-color-scheme: light) {
		--floating-object-shadow:
			inset 0 1px 1px 1px hsl(0, 0%, 100%),
			0 15px 30px -10px hsla(206, 10%, 28%, 0.6);
		--inspector-inset-shadow: inset 0 1px 4px -2px hsla(0, 0%, 0%, 0.3);
		--popup-menu-shadow:
			0 15px 30px -10px hsla(206, 10%, 28%, 0.6);
	}
	@media (prefers-color-scheme: dark) {
		--floating-object-shadow:
			inset 0 0 0 1px hsla(0, 0%, 100%, 0.12),
			0 10px 30px -10px hsla(0, 0%, 0%, 1);
		--inspector-inset-shadow: inset 0 1px 4px -2px hsla(0, 0%, 0%, 0.9);
		--popup-menu-shadow:
			0 10px 30px -10px hsla(0, 0%, 0%, 1);
	}
}