.Toolbar {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	padding: 0 5px;

	> .group {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		> button,
		> .StateButton {
			margin: 0 5px;
		}
	}

	@media (prefers-color-scheme: light) {
		background: linear-gradient(to top, hsla(0, 0%, 0%, 0.01), hsla(222, 71%, 5%, 0.1));
	}

	@media (prefers-color-scheme: dark) {
		background: linear-gradient(to top, hsla(0, 0%, 0%, 0.01), hsla(222, 20%, 5%, 0.5));
	}
}