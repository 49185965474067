body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica", "Roboto", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

span, div, img {
	-moz-user-select: -moz-none;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	user-select: none;
}

code,
pre {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
	-moz-user-select: -moz-all;
	user-select: all;
}